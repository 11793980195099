import React, { Component, Fragment } from "react";
import AssesmentCard from "./AssesmentCard";
import AssesmentStart from "./AssesmentStart";
import Checkbox from "./Checkbox";

import {
  Welcome_to_AIRR,
  text_style,
  Start_an_Assessment,
  Welcome,
  AssessmentDetail,
  Bold,
  BlurText,
  Text,
  Loading,
  LoadingLogo,
  EmailVerify,
  Resend
} from "./Home.module.scss";

import {
  ListAllAssesment,
  CreateAssesment,
  SelectAssesment,
  ResendVerifyEmail
} from "../Actions/AssesmentActions";
import { connect } from "react-redux";
import _ from "lodash";
import { isEmailVerified } from "../Util/Auth";
import { withRouter } from "react-router-dom";
import { GetUser } from "../Actions/UsersActions";
import { withToastManager } from "react-toast-notifications";

export class Home extends Component {
  routeTo = (route, state) => {
    this.props.history.push(`/${route}`, state);
  };

  componentDidMount() {
    if (_.isNil(_.get(this.props, "user"))) {
      this.props.getUser();
    }
    this.props.listAllAssesment();
  }

  startAssesment = () => {
    /*if (!isEmailVerified()) {
      this.props.toastManager.add(
        "Please verify your account before starting an assessment",
        { appearance: "error", autoDismiss: true },
        () => {}
      );
    } else {
      this.props.createAssesment();
      this.routeTo("assesment", { type: "start" });
    }*/
    this.props.createAssesment();
    this.routeTo("assesment", { type: "start" });
  };

  getReport = assessmentId => {
    this.routeTo(`report`, { assessmentId: assessmentId, type: "report" });
  };

  getAssesment = data => {
    this.props.selectAssesment(data.assessmentId);
    this.routeTo(`assesment`, data);
  };

  checkLoadAssesments = assesments => {
    return _.isEmpty(assesments);
  };

  getAssesmentCards = (assesments, type) => {
    return _.filter(assesments, { status: type }).map(assesment => {
      return (
        <div key={assesment.id} className="col-md-3 col-sm-6 col-12">
          <AssesmentCard
            id={assesment.id}
            status={assesment.status}
            getReport={this.getReport}
            total={assesment.totalScore}
            createdOn={assesment.createdOn}
            getAssesment={this.getAssesment}
            paymentStatus={assesment.paymentStatus}
            lastCategoryId={assesment.lastCategoryId}
            lastQuestionId={assesment.lastQuestionId}
          />
        </div>
      );
    });
  };

  checkAssesments = (assesments, type) => {
    return !_.isEmpty(_.filter(assesments, { status: type }));
  };

  checkEmailVerify = () => {
    if (isEmailVerified()) {
      return true;
    } else {
      return _.get(this.props, "emailsent", false);
    }
  };

  render() {
    const { assesments, loading } = this.props;
    if (_.get(this.props, "loading")) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className={Loading}>
                <img src="assets/Loadinglogo.svg" className={LoadingLogo} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.checkLoadAssesments(assesments) ? (
      <Fragment>
        <div
          className="container-fluid"
          style={{
            /*position: "sticky",*/
            height: "100%",
            zIndex: "-9000",
            position: "fixed",
            padding: "0px"
          }}
        >
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-6"></div>
            <div className="col-md-6 col-sm-6 col-6">
              <img
                src="assets/Dashboard_HD.png"
                style={{ bottom: "0px" }}
              ></img>
            </div>
          </div>
        </div>
        <div className="container">
          {this.checkEmailVerify() ? null : (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div className="alert alert-warning" role="alert">
                  <p className={EmailVerify}>
                    Your account is not verified! Follow the steps in the
                    confirmation email. Didn't receive it?{" "}
                    <b
                      className={Resend}
                      onClick={this.props.resendVerifyEmail}
                    >
                      Resend
                    </b>
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-4 col-sm-12 col-12">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <p className={Welcome_to_AIRR}>
                    Welcome to <strong className={text_style}>CRR</strong>
                  </p>
                  <p className={Start_an_Assessment}>
                    Start an Assessment and answer a series of questions to
                    check the readiness of your company to invest in Cybersecurity.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div style={{ margin: "14px" }} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <Checkbox />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div style={{ margin: "14px" }} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <AssesmentStart
                    type="home"
                    startAssesment={this.startAssesment}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div style={{ margin: "14px" }} />
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12 col-12"></div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div style={{ height: "14px" }} />
            </div>
          </div>
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div
          className="container-fluid"
          style={{
            //position: "sticky",
            height: "100%",
            zIndex: "-9000",
            position: "fixed",
            padding: "0px"
          }}
        >
          <div className="row">
            <div className=" col-md-6 col-sm-6 col-6"></div>
            <div className="col-md-6 col-sm-6 col-6">
              <img
                src="assets/Dashboard_HD.png"
                style={{ bottom: "0px" }}
              ></img>
            </div>
          </div>
        </div>

        <div className="container">
          {this.checkEmailVerify() ? null : (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div class="alert alert-warning" role="alert">
                  <p className={EmailVerify}>
                    Your account is not verified! Follow the steps in the
                    confirmation email. Didn't receive it?{" "}
                    <b
                      className={Resend}
                      onClick={this.props.resendVerifyEmail}
                    >
                      Resend
                    </b>
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-6 col-sm-12 col-12">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <span className={Welcome}>
                    Welcome to <span className={Bold}>CRR</span>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <span className={AssessmentDetail}>
                    Start an Assessment and answer a series of questions to
                    check the readiness of your company to invest in Cybersecurity.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-12 col-12"></div>
            <div className="col-md-5 col-sm-12 col-12">
              <AssesmentStart startAssesment={this.startAssesment} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12 col-12">
              {this.checkAssesments(assesments, "DRAFT") ? (
                <Fragment>
                  <p className={BlurText}>Draft</p>
                  <p className={Text}>Draft</p>
                </Fragment>
              ) : null}
            </div>
            <div className="col-md-8 col-sm-12 col-12"></div>
          </div>
          <div className="row">
            {this.getAssesmentCards(assesments, "DRAFT")}
          </div>
          <div className="row"></div>
          <div className="row">
            <div className="col-md-4 col-sm-12 col-12">
              {this.checkAssesments(assesments, "COMPLETED") ? (
                <Fragment>
                  <p className={BlurText}>Completed</p>
                  <p className={Text}>Completed</p>
                </Fragment>
              ) : null}
            </div>
            <div className="col-md-8 col-sm-12 col-12"></div>
          </div>
          <div className="row">
            {this.getAssesmentCards(assesments, "COMPLETED")}
          </div>
        </div>
      </Fragment>
    );
  }
}

let mapStateToProps = (state, props) => {
  return {
    user: state.user.user,
    assesments: state.assesment.assesments,
    loading: state.assesment.loading,
    emailsent: state.assesment.emailsent,
    isNew: state.assesment.isNew
  };
};

let mapDispatchToProps = dispatch => {
  return {
    getUser: () => {
      dispatch(GetUser());
    },
    listAllAssesment: () => {
      dispatch(ListAllAssesment());
    },
    createAssesment: () => {
      dispatch(CreateAssesment());
    },
    selectAssesment: data => {
      dispatch(SelectAssesment(data));
    },
    resendVerifyEmail: () => {
      dispatch(ResendVerifyEmail());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(withRouter(Home)));
