import React, { Component } from "react";
import {
  Welcome_to_AIRR,
  text_style_1,
  Start_an_Assessment,
  Dont_have_an_account,
  Signup,
  Forgot_password,
  Demo_2,
  Submit,
  TopMargin,
  ButtonStyle,
  Arrow
} from "./Reset.module.scss";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import { ForgotPassword, ForgotPasswordSubmit } from "../Util/Auth";
import { toLowerCase } from "../Util/Validate";

class Reset extends Component {
  state = {
    reset: false,
    errorMessage: "",
    email: "",
    code: "",
    password01: "",
    password02: "",
    pass01type: true,
    pass02type: true
  };

  routeTo = route => {
    this.props.history.push(`/${route}`);
  };

  showPassword = type =>
    this.setState(state => {
      return { [type]: !state[type] };
    });

  forgotPassword = () => {
    let { email } = this.state;
    ForgotPassword({ username: toLowerCase(email) }, (error, success) => {
      if (error) {
        this.setState({ errorMessage: error.message });
      } else {
        this.setState({ uemail: toLowerCase(email), email: "", reset: true });
      }
    });
  };

  forgotPasswordSubmit = cb => {
    let { uemail, code, password01, password02 } = this.state;
    let { routeTo } = this;
    ForgotPasswordSubmit(
      { username: toLowerCase(uemail), code: code, new_password: password01 },
      (error, success) => {
        if (!_.isNull(error)) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState(
            { code: "", password01: "", password02: "", uemail: "" },
            () => {
              this.props.toastManager.add(
                "User Credentials Changed",
                {
                  appearance: "success",
                  autoDismiss: true,
                  pauseOnHover: true
                },
                () => {
                  cb();
                }
              );
            }
          );
        }
      }
    );
  };

  handleOnChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value, errorMessage: null });

  checkPasswords = () =>
    _.isEqual(
      _.get(this.state, "password01"),
      _.get(this.state, "password02")
    ) &&
    !_.isEqual(_.get(this.state, "password01"), "") &&
    !_.isEqual(_.get(this.state, "password02"), "");

  resetForm = () => {
    let { reset, pass01type, pass02type } = this.state;
    return reset ? (
      <form>
        <div className="form-group">
          <input
            name="code"
            type="text"
            className="form-control"
            placeholder="Code"
            onChange={this.handleOnChange}
          />
        </div>
        <div className="form-group">
          <div className="input-group">
            <input
              name="password01"
              type={pass01type ? "password" : "text"}
              className="form-control"
              placeholder="Password"
              onChange={this.handleOnChange}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <img
                  onClick={() => this.showPassword("pass01type")}
                  className="Show-password"
                  src="assets/show-password.png"
                  alt=""
                  srcSet="assets/show-password@2x.png 2x,
                  assets/show-password@3x.png 3x"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="input-group">
            <input
              name="password02"
              type={pass02type ? "password" : "text"}
              className="form-control"
              placeholder="Confirm Password"
              onChange={this.handleOnChange}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <img
                  onClick={() => this.showPassword("pass02type")}
                  className="Show-password"
                  src="assets/show-password.png"
                  alt=""
                  srcSet="assets/show-password@2x.png 2x,
                  assets/show-password@3x.png 3x"
                />
              </span>
            </div>
          </div>
        </div>
      </form>
    ) : (
      <form>
        <div className="form-group">
          <p>Enter your E-mail address</p>
        </div>
        <div className="form-group">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Work Email"
            onChange={this.handleOnChange}
          />
        </div>
      </form>
    );
  };

  render() {
    let { reset } = this.state;
    return (
      <div className="container">
        <div className="row"></div>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <span className={Welcome_to_AIRR}>
                  Welcome to <span className={text_style_1}>CRR</span>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <span className={Start_an_Assessment}>
                  Start an Assessment and answer a series of questions to check
                  the readiness of your company to invest in Artificial
                  Intelligence.
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className={TopMargin}></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12">{this.resetForm()}</div>
              <div className="col-md-4 col-sm-4">
                <button
                  className={ButtonStyle}
                  onClick={() => {
                    reset
                      ? this.forgotPasswordSubmit(() => {
                          this.routeTo("login");
                        })
                      : this.forgotPassword();
                  }}
                >
                  {reset ? "Reset" : "Send code"}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className={TopMargin}></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <span className={Dont_have_an_account}>
                  Don’t have an account ? {"   "}
                  <Link className={Signup} to="/register">
                    Signup
                  </Link>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {this.state.errorMessage ? (
                  <div className="alert alert-danger alert-with-icon">
                    <button
                      type="button"
                      aria-hidden="true"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <i className="tim-icons icon-simple-remove"></i>
                    </button>
                    <span>
                      <b> Error! - </b> {this.state.errorMessage}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img src="assets/Login_HD.png" className={Demo_2} alt="" />
          </div>
        </div>
        <div className="row"></div>
      </div>
    );
  }
}

export default withToastManager(withRouter(Reset));
